import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook from your context
import { useNavigate } from 'react-router-dom';
import endaLogo from '../assets/images/endaLogo.png';
import { useTheme } from '@mui/material/styles'; // Import useTheme hook

const AccessPage = () => {
  const theme = useTheme(); // Use the theme hook
  const [subscriptionId, setSubscriptionId] = useState(localStorage.getItem('subscriptionId') || ''); // Load from localStorage if available
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (subscriptionId) {
      localStorage.setItem('subscriptionId', subscriptionId); // Store subscriptionId in localStorage
    }
  }, [subscriptionId]); // Update localStorage whenever subscriptionId changes

  const handleSubscriptionIdSubmit = async (event) => {
    event.preventDefault();

    try {
      const url = `https://endademoapimanager.azure-api.net/subscription/verifySubscriptionId?subscriptionId=${encodeURIComponent(subscriptionId)}`;
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors', // Explicitly setting CORS mode
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': subscriptionId, // Ensure this is the correct subscription key
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch');
      }

      const result = await response.text(); // Use text() since the API response is plain text

      if (result === 'Subscription ID is valid') {
        login(); // Update the auth context
        navigate('/'); // Navigate to the dashboard or home page
      } else {
        setError('Invalid subscription ID'); // Set error state if subscription ID is not valid
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to verify activation key'); // Update the UI to show error feedback
    } 
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f4f8' }}>
      <div style={{ backgroundColor: 'white', padding: '40px', borderRadius: '20px', boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)', width: '400px', textAlign: 'center' }}>
        
        {/* Add your logo here */}
        <img src={endaLogo} alt="Logo" style={{ width: '100px', marginBottom: '60px' }} />

        {/* Activation Key Label and Input */}
        <form onSubmit={handleSubscriptionIdSubmit}>
          <div style={{ marginBottom: '60px' }}>
            <label htmlFor="subscriptionId" style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Activation Key</label>
            <input
              type="text"
              id="subscriptionId"
              value={subscriptionId}
              onChange={(e) => setSubscriptionId(e.target.value)} // Update the setter
              required
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid #ccc',
                fontSize: '16px',
              }}
            />
          </div>

          {/* Error Message */}
          {error && <p style={{ color: 'red' }}>{error}</p>}

          {/* Submit Button */}
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              border: 'none',
              borderRadius: '10px',
              fontSize: '16px',
              cursor: 'pointer',
            }}
          >
            Access Demo
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccessPage;

