
// API urls
// const url_db_manager_server = "http://127.0.0.1:5002";
const url_db_manager_server = "https://tmp-db-management-service-hnefa9deangdh4g4.canadaeast-01.azurewebsites.net"


export const createNewConsultation = async () => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');

        const response = await fetch(url_db_manager_server + `/create_consultation?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({}),
        });

        if (response.ok) {
            const { id } = await response.json();  // Destructure the returned JSON
            console.log(`consultation ${id} created successfully`);
            return id;

        } else {
            console.error('Failed to create the new consultation');
            return null;
        }
    } catch (error) {
        console.error('An error occurred while creating the new consultation:', error);
        return null;
    }
}


export const fetchConsultationTitles = async () => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');

        const response = await fetch(url_db_manager_server + `/get_consultations_titles?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),                
            },
        });

        if (response.ok) {
            const { consultations } = await response.json();  // Destructure the returned JSON
            console.log('Consultations fetched successfully');
            return consultations;
        } else {
            console.error('Failed to fetch the consultations');
            return null;
        }

    } catch (error) {
        console.error('An error occurred while fetching the consultations:', error);
        return null;
    }
}


// src/services/consultationService.js
export const fetchConsultationDetails = async (id) => {
    console.log("fetching consultation details");
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');

        const response = await fetch(`${url_db_manager_server}/get_consultation_details/${id}?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
        });

        if (response.ok) {
            const consultation = await response.json();
            return consultation;
        } else {
            console.error('Failed to fetch the consultation details');
            return null;
        }

    } catch (error) {
        console.error('An error occurred while fetching the consultation details:', error);
        return null;
    }
};


// Function to update the database with transcription data
export const updateConsultationTranscript = async (id, transcript) => {
    try {
        console.log(transcript);

        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');

        const response = await fetch(url_db_manager_server + `/update_transcript?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({ id, transcript }),
        });

        if (response.ok) {
            console.log('Database updated successfully');
        } else {
            console.error('Failed to update the database');
        }
    } catch (error) {
        console.error('An error occurred while updating the database:', error);
    }
};



export const updateConsultationName = async (id, name) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');

        const response = await fetch(url_db_manager_server + `/update_name?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({id, name}),
        });

        if (response.ok) {
            console.log('Database updated successfully');
        } else {
            console.error('Failed to update the database');
        }
    } catch (error) {
        console.error('An error occurred while updating the database:', error);
    }
};



export const updateConsultationDate = async (id, date) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/update_date?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({id, date}),
        });

        if (response.ok) {
            console.log('Database updated successfully');
        } else {
            console.error('Failed to update the database');
        }
    } catch (error) {
        console.error('An error occurred while updating the database:', error);
    }
};

export const updateConsultationNotes = async (id, notes) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/update_consultation_notes?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({id, notes}),
        });

        if (response.ok) {
            console.log('Database updated successfully');
        } else {
            console.error('Failed to update the database');
        }
    } catch (error) {
        console.error('An error occurred while updating the database:', error);
    }
};


export const createSummary = async (id) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/create_summary?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({id}),
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data;  // Return the parsed datas
        } else {
            console.error('Failed to update the database');
        }
    } catch (error) {
        console.error('An error occurred while updating the database:', error);
    }
};


export const updateSummary = async (summary_id, summary) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/update_summary?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({summary_id, summary}),
        });

        if (response.ok) {
            console.log('Summary updated successfully');
        } else {
            console.error('Failed to update the database');
        }
    } catch (error) {
        console.error('An error occurred while updating the database:', error);
    }
};



export const getSummary = async (summary_id) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/get_summary?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({summary_id}),
        });

        if (response.ok) {
            console.log('Get summary success');
        } else {
            console.error('Failed to update the database');
        }
    } catch (error) {
        console.error('An error occurred while updating the database:', error);
    }
};


export const getSummaryIds = async (consultation_id) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/get_summary_ids?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({ consultation_id }),
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data;  // Return the parsed data
        } else {
            console.error('Failed to fetch summary IDs');
            return [];  // Return an empty array or handle the error as needed
        }
    } catch (error) {
        console.error('An error occurred while fetching summary IDs:', error);
        return [];  // Return an empty array or handle the error as needed
    }
};



export const deleteSummaryFromBackend = async (summary_id) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/delete_summary?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({ summary_id }),
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data;  // Return the parsed data
        } else {
            console.error('Failed to fetch summary IDs');
            return [];  // Return an empty array or handle the error as needed
        }
    } catch (error) {
        console.error('An error occurred while fetching summary IDs:', error);
        return [];  // Return an empty array or handle the error as needed
    }
};


export const getSummaries = async (consultation_id) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/get_summaries?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({ consultation_id }),
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data;  // Return the parsed data
        } else {
            console.error('Failed to fetch summary IDs');
            return [];  // Return an empty array or handle the error as needed
        }
    } catch (error) {
        console.error('An error occurred while fetching summary IDs:', error);
        return [];  // Return an empty array or handle the error as needed
    }
};


export const getTemplates = async () => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/get_templates?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data.templates;  // Return the parsed data
        } else {
            console.error('Failed to fetch summary IDs');
            return [];  // Return an empty array or handle the error as needed
        }
    } catch (error) {
        console.error('An error occurred while fetching summary IDs:', error);
        return [];  // Return an empty array or handle the error as needed
    }
};

export const updateSummaryTemplateId = async (summary_id, template_id) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/update_summary_template_id?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({ summary_id, template_id}),
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data;  // Return the parsed data
        } else {
            console.error('Failed to fetch summary IDs');
            return [];  // Return an empty array or handle the error as needed
        }
    } catch (error) {
        console.error('An error occurred while fetching summary IDs:', error);
        return [];  // Return an empty array or handle the error as needed
    }
};


export const updateTemplate = async (template_id, content) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/update_template?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({template_id, content}),
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data;  // Return the parsed data
        } else {
            console.error('Failed to update template');
            return [];  // Return an empty array or handle the error as needed
        }
    } catch (error) {
        console.error('An error occurred while updating template :', error);
        return [];  // Return an empty array or handle the error as needed
    }
};


export const addTemplate = async () => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/add_template?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({}),
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data;  // Return the parsed data
        } else {
            console.error('Failed to add template');
            return [];  // Return an empty array or handle the error as needed
        }
    } catch (error) {
        console.error('An error occurred while adding template :', error);
        return [];  // Return an empty array or handle the error as needed
    }
};


export const deleteTemplate = async (template_id) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/delete_template?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({template_id}),
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data;  // Return the parsed data
        } else {
            console.error('Failed to delete template');
            return [];  // Return an empty array or handle the error as needed
        }
    } catch (error) {
        console.error('An error occurred while deleting template :', error);
        return [];  // Return an empty array or handle the error as needed
    }
};

export const updateTemplateTitle = async (template_id, title) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/update_template_title?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({template_id, title}),
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data;  // Return the parsed data
        } else {
            console.error('Failed to update template title');
            return [];  // Return an empty array or handle the error as needed
        }
    } catch (error) {
        console.error('An error occurred while updating template title :', error);
        return [];  // Return an empty array or handle the error as needed
    }
};


export const deleteConsultation = async (consultation_id) => {
    try {
        // Fetch subscriptionId from localStorage
        const subscriptionId = localStorage.getItem('subscriptionId');
        const response = await fetch(url_db_manager_server + `/delete_consultation?subscriptionId=${encodeURIComponent(subscriptionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': encodeURIComponent(subscriptionId),
            },
            body: JSON.stringify({consultation_id}),
        });

        if (response.ok) {
            const data = await response.json();  // Parse the JSON response
            return data;  // Return the parsed data
        } else {
            console.error('Failed to delete template');
            return [];  // Return an empty array or handle the error as needed
        }
    } catch (error) {
        console.error('An error occurred while deleting template :', error);
        return [];  // Return an empty array or handle the error as needed
    }
};