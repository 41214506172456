import React from 'react';

function Home() {
    return (
        <div>
            <h1>Information</h1>
            <p>Licence, disclaimer and software information concerning ScribeAid by Enda Technologies.</p>
            <p>Version : ScribeAid V0.1.0</p>
        </div>
    );
}

export default Home;