import React from 'react';
import { Box, Typography } from '@mui/material';
import EndaLogo from '../assets/images/endaLogo.png'; // Adjust the path to your logo

function Home() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh', // Full viewport height
                bgcolor: 'background.default', // Background color from theme
                textAlign: 'center',
                overflow: 'hidden', // Prevents any scrollbars
                position: 'relative', // To position the copyright at the bottom
            }}
        >
            <Box sx={{ width: '420px' }}> {/* Set a fixed width for both the logo and text */}
                <img src={EndaLogo} alt="Enda Logo" style={{ width: '100%', marginBottom: '10px' }} />
                <Typography variant="h4" sx={{ color: 'text.primary' }}>
                    AI Healthcare Solutions
                </Typography>
            </Box>

            {/* Copyright Statement */}
            <Typography
                variant="body2"
                sx={{
                    color: 'text.secondary',
                    position: 'absolute',
                    bottom: 50, // Position it at the bottom of the viewport
                    textAlign: 'center',
                    width: '100%', // Ensure it spans the full width
                }}
            >
                © 2024 Enda Technologies. All rights reserved.
            </Typography>
        </Box>
    );
}

export default Home;