import React, { useState, useEffect, useRef } from 'react';
import { Box, Tabs, Tab, Typography, TextField, FormControl, InputLabel, Select, MenuItem, IconButton, Skeleton } from '@mui/material';
import templates from '../data/templatesData'; 
import { dispatchContextUpdateEvent, useConsultationId } from '../context/ConsultationIdContext';
import { updateConsultationNotes, getTemplates, updateTemplate, addTemplate, deleteTemplate, updateTemplateTitle } from '../services/databaseService';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const DocumentationTabs = ({ isLoadingAnimation }) => {
  const { consultationDetails } = useConsultationId();
  const { id, transcript, notes: contextNotes } = consultationDetails;

  const [selectedTab, setSelectedTab] = useState(0);
  const [notes, setNotes] = useState(contextNotes);  // State to hold the notes content
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  
  const [templates, setTemplates] = useState([]);
  const [templateContent, setTemplateContent] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');

  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [editingItemId, setEditingItemId] = useState(null);

  const [isTitleInvalid, setIsTitleInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const previousIsLoadingAnimation = useRef(isLoadingAnimation);  // Ref to store previous value

  // Set the selected tab to "Transcript" when isLoadingAnimation transitions from false to true
  useEffect(() => {
    if (!previousIsLoadingAnimation.current && isLoadingAnimation) {
      setSelectedTab(1);  // 1 corresponds to the "Transcript" tab
    }
    previousIsLoadingAnimation.current = isLoadingAnimation;  // Update previous value
  }, [isLoadingAnimation]);


  useEffect(() => {
    setSelectedTab(0);
    setSelectedTemplate(0);
    setTemplateContent('');
  }, [id]);


  useEffect(() => {
    setNotes(contextNotes);
  }, [id, contextNotes]);


  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await getTemplates();
        // Sort response by id in ascending order
        const sortedResponse = response.sort((a, b) => a.id - b.id);
        
        setTemplates(sortedResponse);
      } catch (error) {
        console.error('Failed to fetch templates:', error);
      }
    };
  
    fetchTemplates();

  }, [id, templateContent, selectedTemplate, templateTitle]);


  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);  // Update notes content
  };

  const handleTemplateChange = (templateId) => {
    const selected = templates.find(template => template.id === templateId);
    setSelectedTemplate(selected?.id || null);  // Set the selected template by ID
    setTemplateContent(selected ? selected.content : '');  // Set content if template exists
    setHoveredItemId(null);  // Reset hover state
  };

  const handleNotesBlur = async () => {
    await updateConsultationNotes(id, notes);
    dispatchContextUpdateEvent();
  };

  const handleTemplateContentChange = (event) => {
    setTemplateContent(event.target.value);
  }

  const handleTemplateContentBlur = async () => {
    if (selectedTemplate !== null) {
      await updateTemplate(selectedTemplate, templateContent);  // Update template by ID
      dispatchContextUpdateEvent();
    }
  };

  const handleAddTemplate = async () => { 
    const data = await addTemplate(); // The ID returned from the database
    const newTemplateId = data.template_id;
    const newTemplate = { id: newTemplateId, title: '', content: '' };
    
    // Update the templates state with the new template added
    setTemplates(prevTemplates => [...prevTemplates, newTemplate]);
    
    // After updating templates, set the selected template
    setSelectedTemplate(newTemplateId);
    setTemplateContent(""); // Remove the content of the template
    setEditingItemId(newTemplateId);
    
    dispatchContextUpdateEvent(); // dispatch  context update event to update summary templates
  };

  const handleUpdateTemplateTitle = (id) => {
    const template = templates.find(template => template.id === id);
    if (template) {
      setEditingItemId(id);
      setTemplateTitle(template.title);
      console.log("disable select:")
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    await deleteTemplate(templateId);
    setTemplates(templates.filter(template => template.id !== templateId));  // Remove from state

    // If the deleted template was the currently selected one, reset the selection and content
    if (templateId === selectedTemplate) {
      setSelectedTemplate(null); // Deselect the template
      setTemplateContent(''); // Clear the content
    }
    
    dispatchContextUpdateEvent();
    console.log("Template has been deleted...");
  };


  const handleSaveUpdatedTitle = async () => {
    console.log("Saving updated title...");
    
    const updatedTemplates = templates.map(template =>
      template.id === editingItemId ? { ...template, title: templateTitle } : template
    );
  
    setTemplates(updatedTemplates);
    await updateTemplateTitle(editingItemId, templateTitle);
    dispatchContextUpdateEvent();
  
    // Reset editing state
    setEditingItemId(null);
    setTemplateTitle('');
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log("Enter key pressed, saving title...");
      handleSaveUpdatedTitle();
      setSelectedTemplate(editingItemId);
    }
  };

  const handleEditTitleBlur = () => {
    if (templateTitle.trim() === '') {
      setIsTitleInvalid(true); // Set the border to red
      setErrorMessage('Please provide a title'); // Set the error message
    } else {
      setIsTitleInvalid(false); // Remove the red border
      setErrorMessage(''); // Clear the error message
      handleSaveUpdatedTitle(); // Save the title if it's valid
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh'}}>
      <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="documentation tabs">
        <Tab label="Notes" />
        <Tab label="Transcript" />
        <Tab label="Templates" />
      </Tabs>
      <Box
        role="tabpanel"
        hidden={selectedTab !== 0}
        sx={{ p: 2, flexGrow: 1, overflow: 'auto' }}  // Adjust to use available space
      >
        <TextField
          label="Notes"
          multiline
          fullWidth
          variant="outlined"
          value={notes}
          onChange={handleNotesChange}
          onBlur={handleNotesBlur}
          rows={13}  // Adjust to take up more vertical space
        />
      </Box>
      <Box
        role="tabpanel"
        hidden={selectedTab !== 1}
        sx={{ p: 2, flexGrow: 1, overflow: 'auto' }}
      >
        { isLoadingAnimation ?

        <Skeleton
          variant="rounded" 
          width="100%" 
          height="330px"
          sx={{
            borderRadius: '4px',  // Matches the TextField's border radius
            padding: '16.5px 14px',  // Matches the TextField's padding
            boxSizing: 'border-box',  // Ensure padding is included in the width and height
            border: '1px solid rgba(0, 0, 0, 0.23)'
          }}
        />

        :
        <TextField
          label="Transcript"
          multiline
          fullWidth
          variant="outlined"
          value={transcript || 'The transcript content will be accessible here once the consultation recording is over.'}
          InputProps={{
            readOnly: true,
          }}
          disabled
          rows={13}
        />
        }
        
      </Box>
      <Box
        role="tabpanel"
        hidden={selectedTab !== 2}
        sx={{ p: 2, flexGrow: 1, overflow: 'auto'}}
      >
        <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel id="template-select-label">Select Template</InputLabel>
      {!editingItemId ? (
        <Select
          labelId="template-select-label"
          id="template-select"
          value={selectedTemplate}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "add_template") {
              handleAddTemplate();  // Call the specific function for adding a template
            } else {
              handleTemplateChange(value);  // Handle the usual template change
            }
          }}
          label="Select Template"
        >
          {templates.map((template) => (
            <MenuItem
              key={template.id}
              value={template.id}
              onMouseEnter={() => setHoveredItemId(template.id)}
              onMouseLeave={() => setHoveredItemId(null)}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span>{template.title}</span>

                {hoveredItemId === template.id && (
                  <span>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpdateTemplateTitle(template.id);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteTemplate(template.id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </span>
                )}
              </Box>
            </MenuItem>
          ))}
          <MenuItem value="add_template">
            <em>Add Template</em>
          </MenuItem>
        </Select>
      ) : (
        <TextField
          value={templateTitle}
          onChange={(e) => setTemplateTitle(e.target.value)}
          fullWidth
          autoFocus
          onKeyDown={handleKeyDown}  // Save on Enter key press
          onBlur={handleEditTitleBlur}
          sx={{
            borderColor: isTitleInvalid ? 'red' : 'default', // Conditional border color
          }}
          error={isTitleInvalid} // Display error state
          helperText={errorMessage} // Display error message
        />
      )}
    </FormControl>
          <TextField
            fullWidth
            multiline
            value={templateContent}
            variant="outlined"
            rows={10}  // Adjust based on your content size
            onChange={handleTemplateContentChange}
            onBlur={handleTemplateContentBlur}
          />
      </Box>
    </Box>
  );
};

export default DocumentationTabs;