import React from 'react';

function Home() {
    return (
        <div>
            <h1>My Profile</h1>
            <p>Here you can modify your practionner account, manage your subscription and your preferences.</p>
        </div>
    );
}

export default Home;