// API urls
const url_summarizing_server = "http://52.151.206.34:5000";

export const generateSummary = async (transcript, template, notes ) => {
    try {
        const response = await fetch(url_summarizing_server + '/summarize', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ transcript, template, notes }),
        });

        if (response.ok) {
            const { summary } = await response.json();
            console.log('Summary generated successfully:', summary);
            return summary;
        } else {
            console.error('Failed to generate summary');
            return null;
        }
    } catch (error) {
        console.error('An error occurred while generating the summary:', error);
        return null;
    }
}