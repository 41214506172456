import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import NewConsultation from './pages/NewConsultation';
import ConsultationHistory from './pages/ConsultationHistory';
import Settings from './pages/Settings';
import MyProfile from './pages/MyProfile';
import Information from './pages/Information';
import NotFound from './pages/NotFound';
import HomePage from './pages/HomePage';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';
import ProtectedRoute from './components/ProtectedRoute';
import AccessPage from './pages/AccessPage';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <MainLayout />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

function MainLayout() {
  const location = useLocation(); // Get the current location
  const showNavbar = location.pathname !== '/access'; // Condition to show the Navbar

  return (
    <div style={{ display: 'flex' }}>
      {showNavbar && <Navbar />}
      <main style={{ flexGrow: 1, padding: '20px' }}>
        <Routes>
          <Route path="/access" element={<AccessPage />} />
          <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
          <Route path="/consultation" element={<ProtectedRoute><NewConsultation /></ProtectedRoute>} />
          <Route path="/consultation-history" element={<ProtectedRoute><ConsultationHistory /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/my-profile" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
          <Route path="/information" element={<ProtectedRoute><Information /></ProtectedRoute>} />
          <Route path="*" element={<ProtectedRoute><NotFound /></ProtectedRoute>} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
