import React, { useState, useEffect } from 'react';
import { CssBaseline, Box, Container, IconButton, Typography, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Skeleton } from '@mui/material';
import { PlayArrow as PlayArrowIcon, Pause as PauseIcon, Stop as StopIcon } from '@mui/icons-material';
import DocumentationTabs from '../components/DocumentationTabs';
import ConsultationSummaryTabs from '../components/ConsultationSummaryTabs';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { startRecording, pauseRecording, stopRecording } from '../services/recordingService';
import { updateConsultationName, updateConsultationDate, updateConsultationTranscript } from '../services/databaseService';
import { useConsultationId, dispatchContextUpdateEvent } from '../context/ConsultationIdContext';

function NewConsultation() {
    const { consultationId, consultationDetails, setConsultationId, setConsultationDetails } = useConsultationId();
    const { id, name: contextName, date: contextDate } = consultationDetails;

    const [name, setName] = useState(contextName || '');
    const [date, setDate] = useState(contextDate || dayjs());

    const [timerOn, setTimerOn] = useState(false);
    const [startTime, setStartTime] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [pauseTime, setPauseTime] = useState(0); // Track the accumulated pause time
    const [isRecording, setIsRecording] = useState(false);
    const [recordingInitiated, setRecordingInitiated] = useState(false); 
    const [consentGiven, setConsentGiven] = useState(false);
    const [openConsentDialog, setOpenConsentDialog] = useState(false);
    const [openStopDialog, setOpenStopDialog] = useState(false);
    const [transcription, setTranscription] = useState('');

    const [isLoadingAnimation, setIsLoadingAnimation] = useState(false);

    const [mediaStream, setMediaStream] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [ws, setWs] = useState(null);
    const [isPaused, setIsPaused] = useState(false);

   

    // Update local state when consultationId or consultationDetails change
    useEffect(() => {
        setName(contextName || '');
        setDate(contextDate || dayjs());
    }, [contextName, contextDate]);

    useEffect(() => {
        let interval = null;
        if (timerOn) {
            interval = setInterval(() => {
                setElapsedTime(Date.now() - startTime + pauseTime);
            }, 100);
        } else if (!timerOn && interval) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timerOn, startTime, pauseTime]);

    const formatTime = (time) => {
        const seconds = `0${Math.floor((time / 1000) % 60)}`.slice(-2);
        const minutes = `0${Math.floor((time / 60000) % 60)}`.slice(-2);
        const hours = `0${Math.floor(time / 3600000)}`.slice(-2);
        return `${hours}:${minutes}:${seconds}`;
    };


    const handleRecordClick = async () => {
        if (isPaused) {
            console.log('Resuming audio recording...');
            if (mediaRecorder) {
                mediaRecorder.resume();
                setIsPaused(false);
                setTimerOn(true);
                setIsRecording(true);
            }
        } else {
    
            if (!consentGiven) {
                setOpenConsentDialog(true);
            } else {
                if (!recordingInitiated) {
                    setStartTime(Date.now());
                } else {
                    // Resuming from pause
                    setStartTime(Date.now());
                }
                setTimerOn(true);
                setIsRecording(true);
                setRecordingInitiated(true);
        
                try {
                    console.log('Requesting microphone access...');
                    // Get user's audio stream
                    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    setMediaStream(stream); // Update mediaStream state
        
                    console.log('Initializing WebSocket connection...');
                    
                    // Fetch subscriptionId from localStorage
                    const subscriptionId = localStorage.getItem('subscriptionId');

                    // Initialize WebSocket connection
                    const websocket = new WebSocket(`wss://recording-service-acdfhwh2bddcfghx.canadaeast-01.azurewebsites.net?subscriptionId=${encodeURIComponent(subscriptionId)}`);
                    setWs(websocket); // Update WebSocket state
        
                    websocket.onopen = () => {
                        console.log('WebSocket connection opened.');
                    };
                    websocket.onclose = () => {
                        console.log('WebSocket connection closed.');
                    };
                    websocket.onerror = (error) => {
                        console.error('WebSocket error:', error);
                    };
        
                    // Initialize MediaRecorder to stream audio in small chunks to the server
                    const recorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
                    setMediaRecorder(recorder); // Update mediaRecorder state
        
                    recorder.ondataavailable = (event) => {
                        if (event.data.size > 0 && websocket.readyState === WebSocket.OPEN) {
                            console.log('Sending audio chunk...');
                            websocket.send(event.data); // Send audio chunks to the server via WebSocket
                        } else {
                            console.log('WebSocket not open or no audio chunk to send.');
                        }
                    };
        
                    // Start the recorder to record audio in real-time
                    recorder.start(1000); // Record in chunks of 1 second
        
                    console.log('Recording started...');
                } catch (err) {
                    console.error('Error accessing microphone:', err);
                }
            }
        }
    };

    const handleConsent = async (consent) => {
        if (consent) {
            setStartTime(Date.now());
            setTimerOn(true);
            setIsRecording(true);
            setConsentGiven(true);
            setRecordingInitiated(true);

            if (!recordingInitiated) {
                setStartTime(Date.now());
            } else {
                // Resuming from pause
                setStartTime(Date.now());
            }
            setTimerOn(true);
            setIsRecording(true);
            setRecordingInitiated(true);

            // Start recording in the backend
            try {
                console.log('Requesting microphone access...');
                // Get user's audio stream
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                setMediaStream(stream); // Update mediaStream state
    
                console.log('Initializing WebSocket connection...');
                const subscriptionId = localStorage.getItem('subscriptionId');
        
                // Initialize WebSocket connection
                const websocket = new WebSocket(`wss://recording-service-acdfhwh2bddcfghx.canadaeast-01.azurewebsites.net?subscriptionId=${encodeURIComponent(subscriptionId)}`);
                setWs(websocket); // Update WebSocket state
    
                websocket.onopen = () => {
                    console.log('WebSocket connection opened.');
                };
                websocket.onclose = () => {
                    console.log('WebSocket connection closed.');
                };
                websocket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };
    
                // Initialize MediaRecorder to stream audio in small chunks to the server
                const recorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
                setMediaRecorder(recorder); // Update mediaRecorder state
    
                recorder.ondataavailable = (event) => {
                    if (event.data.size > 0 && websocket.readyState === WebSocket.OPEN) {
                        console.log('Sending audio chunk...');
                        websocket.send(event.data); // Send audio chunks to the server via WebSocket
                    } else {
                        console.log('WebSocket not open or no audio chunk to send.');
                    }
                };
    
                // Start the recorder to record audio in real-time
                recorder.start(1000); // Record in chunks of 1 second
    
                console.log('Recording started...');
            } catch (err) {
                console.error('Error accessing microphone:', err);
            }
        }
        setOpenConsentDialog(false);
    };

    const handleStopClick = () => {
        setOpenStopDialog(true);
    };

    const handleStopRecording = (confirmStop) => {
        if (confirmStop) {
            setElapsedTime(0);
            setPauseTime(0); // Reset pause time
            setTimerOn(false);
            setIsRecording(false);
            setConsentGiven(false);
            setRecordingInitiated(false);
            setIsLoadingAnimation(true);

            // Start animation

            // Call stopRecording without awaiting it
            if (mediaRecorder && mediaRecorder.state !== 'inactive') {
                console.log('Stopping audio recording...');
        
                // Request final audio chunk
                mediaRecorder.requestData();
        
                setTimeout(() => {
                    // Stop the MediaRecorder
                    mediaRecorder.stop();
                    setMediaRecorder(null); // Clear the mediaRecorder state
        
                    // Close the WebSocket connection
                    if (ws) {
                        ws.close();
                        setWs(null); // Clear the WebSocket state
                    }
        
                    // Stop all audio tracks and clear the mediaStream
                    if (mediaStream) {
                        mediaStream.getTracks().forEach(track => track.stop());
                        setMediaStream(null); // Clear the mediaStream state
                    }
        
                    console.log('Recording stopped.');
                    const subscriptionId = localStorage.getItem('subscriptionId');

                    // Create a new WebSocket connection to listen for the transcription
                    const newWs = new WebSocket(`wss://recording-service-acdfhwh2bddcfghx.canadaeast-01.azurewebsites.net?subscriptionId=${subscriptionId}`);
                    
                    newWs.onopen = () => {
                        console.log('New WebSocket connection established for transcription.');
                    };

                    newWs.onmessage = (event) => {
                        // Define an async function to handle the message
                        const handleTranscription = async () => {
                            const data = JSON.parse(event.data);
                            if (data.transcription) {
                                console.log('Received transcription:', data.transcription);
                                // Perform asynchronous update
                                await updateConsultationTranscript(id, data.transcription);
                    
                                // Dispatch context update event
                                dispatchContextUpdateEvent();
                    
                                // Handle the transcription (e.g., update the UI)
                                setIsLoadingAnimation(false); // Stop loading animation when transcription is received
                                newWs.close(); // Close the WebSocket connection after receiving the transcription
                            }
                        };
                    
                        // Call the async function
                        handleTranscription();
                    };

                    newWs.onerror = (error) => {
                        console.error('WebSocket error:', error);
                    };

                    newWs.onclose = () => {
                        console.log('WebSocket connection for transcription closed.');
                    };

                    // Update state with the new WebSocket
                    setWs(newWs);
                }, 500);
            }
        }
        setOpenStopDialog(false);
    };

    const handlePauseClick = async () => {
        setTimerOn(false);
        setIsRecording(false);
        setPauseTime(elapsedTime); // Store the current elapsed time
    
        if (mediaRecorder && mediaRecorder.state === 'recording') {
            console.log('Pausing audio recording...');
    
            mediaRecorder.requestData();
            setTimeout(() => {
                mediaRecorder.pause();
                setIsPaused(true); // Use the state setter to update isPaused
                console.log('Audio recording paused.');
            }, 500);
        }
    };
    

    const handleNameBlur = async () => {
        try {
            if (name !== contextName) {
                await updateConsultationName(id, name); // Update the name in the database
                console.log('Name updated successfully');
            }
        } catch (error) {
            console.error('Failed to update name:', error);
        }
    };

    const handleDateChange = async (newDate) => {
        setDate(newDate);
        try {
            await updateConsultationDate(id, newDate);
            console.log('Date updated successfully');
            dispatchContextUpdateEvent();
        } catch (error) {
            console.error('Failed to update date:', error);
        }
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ display: 'flex', flex: 1, gap: 2 }}>
                        <TextField
                            label="Patient Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={handleNameBlur}
                            variant="outlined"
                            size="small"
                            sx={{ maxWidth: '200px' }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date"
                                value={date}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} size="small" sx={{ maxWidth: '150px' }} />}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography>{formatTime(elapsedTime)}</Typography>
                        <IconButton color="primary" onClick={handleRecordClick} disabled={isRecording} sx={{ borderRadius: '50%', border: '1px solid' }}>
                            <PlayArrowIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={handlePauseClick} disabled={!isRecording} sx={{ borderRadius: '50%', border: '1px solid' }}>
                            <PauseIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={handleStopClick} disabled={!recordingInitiated} sx={{ borderRadius: '50%', border: '1px solid' }}> 
                            <StopIcon />
                        </IconButton>
                    </Box>
                </Box>


                <Box sx={{ flexGrow: 1, display: 'flex', mt: 2, overflow: 'hidden' }}>
                    <Box sx={{ flex: 1, overflow: 'auto', mr: 1 }}>
                        <DocumentationTabs isLoadingAnimation={isLoadingAnimation} />
                    </Box>
                    <Box sx={{ flex: 1, overflow: 'auto', ml: 1 }}>
                        <ConsultationSummaryTabs isLoadingAnimation={isLoadingAnimation} />
                    </Box>
                </Box>
            </Container>

            {/* Consent Dialog */}
            <Dialog open={openConsentDialog} onClose={() => setOpenConsentDialog(false)}>
                <DialogTitle>{"Consent Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are about to start recording a consultation, do you have the patient's consent?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConsent(false)} color="primary">No</Button>
                    <Button onClick={() => handleConsent(true)} color="primary" autoFocus>Yes, proceed to record</Button>
                </DialogActions>
            </Dialog>

            {/* Stop Recording Confirmation Dialog */}
            <Dialog open={openStopDialog} onClose={() => setOpenStopDialog(false)}>
                <DialogTitle>{"Stop the recording?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to stop the recording?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenStopDialog(false)} color="primary">No</Button>
                    <Button onClick={() => handleStopRecording(true)} color="primary" autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default NewConsultation;
