import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, IconButton, Box, Divider, Menu, MenuItem, Typography } from '@mui/material';import MicIcon from '@mui/icons-material/Mic';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles'; // Import useTheme hook
import WhiteLogo from '../assets/images/whiteLogo.png';
import { fetchConsultationTitles, fetchConsultationDetails, createNewConsultation } from '../services/databaseService';
import { useConsultationId } from '../context/ConsultationIdContext';
import DeleteIcon from '@mui/icons-material/Delete'; // Add icons for edit and delete
import { deleteConsultation } from '../services/databaseService';

function Navbar() {
    const theme = useTheme(); // Use the theme hook
    const { setConsultationId } = useConsultationId();

    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [consultations, setConsultations] = useState([]);
    const [hoveredItemId, setHoveredItemId] = useState(null); // Track hovered item


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    const handleMenuItemClick = (id) => {
        return () => {
            handleCloseMenu();
            setConsultationId(id);
        };
    };

    const handleCreateConsultation = async () => {
        console.log("creating a new consultation ...")
        try {
            const newConsultationID = await createNewConsultation();
            if (newConsultationID) {
                setConsultationId(newConsultationID); 
    
                const consultationDetails = await fetchConsultationDetails(newConsultationID);
                if (consultationDetails) {
                    console.log('Consultation details loaded:', consultationDetails);
                    await loadConsultations();
                } else {
                    console.error('Failed to load consultation details');
                }
            } else {
                console.error('Failed to create a new consultation');
            }
        } catch (error) {
            console.error('An error occurred while creating the consultation:', error);
        }
    };

    const loadConsultations = async () => {
        const result = await fetchConsultationTitles();
        if (result) {
            setConsultations(result);
        }
    };

    // Add event listener for 'contextUpdate' event
    useEffect(() => {
        const handleContextUpdate = () => {
            loadConsultations();
        };

        window.addEventListener('contextUpdate', handleContextUpdate);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('contextUpdate', handleContextUpdate);
        };
    }, []);
    
    useEffect(() => {
        loadConsultations(); 
    }, []);


    const handleDelete = async (consultation_id) => {
        console.log("consultation id = " + consultation_id);
        await deleteConsultation(consultation_id);
        console.log("delete button clicked ...");
        loadConsultations();
    }

    return (
        <Drawer
            variant="permanent"
            open={isOpen}
            sx={{
                width: isOpen ? 240 : 56,
                '& .MuiDrawer-paper': {
                    width: isOpen ? 240 : 56,
                    bgcolor: theme.palette.primary.main, // Use primary color from theme
                    color: theme.palette.text.primary,   // Use primary text color from theme
                    transition: 'width 0.3s ease',
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                },
            }}
        >
            <Box sx={{ flex: 1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: isOpen ? '16px' : '16px 8px',
                        justifyContent: 'space-between',
                    }}
                >
                    <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                        {isOpen ? <img src={WhiteLogo} alt="Enda Logo White" style={{ height: '20px' }} /> : null}
                    </Link>
                    <IconButton onClick={toggleDrawer} sx={{ color: theme.palette.primary.contrastText }}>
                        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Box>
                <List>
                    <ListItem button onClick={handleCreateConsultation} component={Link} to="/consultation">
                        <ListItemIcon sx={{ color: theme.palette.primary.contrastText }}><MicIcon /></ListItemIcon>
                        {isOpen && <Typography sx={{ color: theme.palette.primary.contrastText }}>Consultation</Typography>}
                    </ListItem>
                    <ListItem button onClick={handleClick}>
                        <ListItemIcon sx={{ color: theme.palette.primary.contrastText }}><MenuIcon /></ListItemIcon>
                        {isOpen && (
                            <>
                                <Typography sx={{ color: theme.palette.primary.contrastText }}>History</Typography>
                                {anchorEl ? <ArrowDropDownIcon sx={{ color: theme.palette.primary.contrastText }} /> : <ArrowRightIcon sx={{ color: theme.palette.primary.contrastText }} />}
                            </>
                        )}
                    </ListItem>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        {consultations.map((consultation) => (
                            <MenuItem
                                key={consultation.id}
                                component={Link}
                                to={`/consultation`}
                                onClick={handleMenuItemClick(consultation.id)}
                                onMouseEnter={() => setHoveredItemId(consultation.id)}  // Track hover
                                onMouseLeave={() => setHoveredItemId(null)}  // Reset hover
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                               <span>{consultation.title} - {new Date(consultation.date).toLocaleDateString()}</span>
                                {hoveredItemId === consultation.id && (  // Display on hover
                                    <Box>
                                        <IconButton size="small" 
                                                    onClick={(e) => {
                                                        handleDelete(consultation.id);
                                                    }}
                                        >
                                        <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                )}
                            </MenuItem>
                        ))}
                    </Menu>
                </List>
            </Box>
            <Box>
                <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.33)', borderBottomWidth: 2 }} />
                <List>
                    <ListItem button component={Link} to="/my-profile">
                        <ListItemIcon sx={{ color: theme.palette.primary.contrastText }}><PersonIcon /></ListItemIcon>
                        {isOpen && <Typography sx={{ color: theme.palette.primary.contrastText }}>Profile</Typography>}
                    </ListItem>
                    <ListItem button component={Link} to="/information">
                        <ListItemIcon sx={{ color: theme.palette.primary.contrastText }}><InfoOutlinedIcon /></ListItemIcon>
                        {isOpen && <Typography sx={{ color: theme.palette.primary.contrastText }}>Information</Typography>}
                    </ListItem>
                    <ListItem button component={Link} to="/settings">
                        <ListItemIcon sx={{ color: theme.palette.primary.contrastText }}><SettingsIcon /></ListItemIcon>
                        {isOpen && <Typography sx={{ color: theme.palette.primary.contrastText }}>Settings</Typography>}
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
}

export default Navbar;