import React from 'react';

function Settings() {
    return (
        <div>
            <h1>Settings</h1>
            <p>Adjust your application settings here.</p>
        </div>
    );
}

export default Settings;