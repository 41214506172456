import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Redirect them to the home page, or you could have a dedicated login page
    return <Navigate to="/access" />;
  }

  return children;
};

export default ProtectedRoute;
