import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, IconButton, Button, MenuItem, Select, FormControl, InputLabel, TextField, Skeleton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import templates from '../data/templatesData'; 
import { generateSummary } from '../services/summaryService';
import { useConsultationId } from '../context/ConsultationIdContext';
import { createSummary, updateSummary, getSummaries, deleteSummaryFromBackend, getTemplates, updateSummaryTemplateId } from '../services/databaseService';
import { dispatchContextUpdateEvent } from '../context/ConsultationIdContext';

const ConsultationSummaryTabs = ({ isLoadingAnimation }) => {
  const { consultationDetails } = useConsultationId();
  const { id, transcript, notes } = consultationDetails;

  const [summaryTabs, setSummaryTabs] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [templates, setTemplates] = useState([]);

  console.log("notes medicales : " + notes)

  // Create a mapping array for selecting the right template
  const templateIdMap = templates.map(template => template.id);

  useEffect(() => {
    const fetchSummaries = async () => {
      console.log("fetching summaries at id = " + id);
      try {
        const response = await getSummaries(id);
        const sortedResponse = response.sort((a, b) => a.id - b.id);
        
        // console.log(response);

        // Map the response to create summaryTabs array
        const tabsArray = sortedResponse.map((summary, index) => ({
          summaryId: summary.summary_id,
          consultationId: summary.consultation_id,
          templateId: summary.template_id,
          label: summary.title,
          summary: summary.summary,
        }));

        // Set the state with the fetched summaries
        setSummaryTabs(tabsArray);
        console.log(tabsArray);
      } catch (error) {
        console.error('Failed to fetch summaries:', error);
      }
    };


    const fetchTemplates = async () => {
      try {
        const response = await getTemplates();
        const sortedResponse = response.sort((a, b) => a.id - b.id);
        setTemplates(sortedResponse);
      } catch (error) {
        console.error('Failed to fetch templates:', error);
      }
    };

    // Function to handle the context update event
    const handleContextUpdate = () => {
      console.log("Context update received");
      fetchSummaries();
      fetchTemplates();
    };

    fetchSummaries();
    fetchTemplates();
    setActiveTabIndex(0);

    // Add the event listener
    window.addEventListener('contextUpdate', handleContextUpdate);

    // Cleanup the event listener on unmount
    return () => {
        window.removeEventListener('contextUpdate', handleContextUpdate);
    };
  }, [id]);

  

  const handleTabChange = (event, newIndex) => {
    setActiveTabIndex(newIndex);
  };


  const handleTemplateChange = async (templateId) => {
    
    try {
      // update the template id in the summary database
      await updateSummaryTemplateId(summaryTabs[activeTabIndex].summaryId, templateId);

      // Update the local state to reflect the change in the UI
      setSummaryTabs((prevTabs) => {
        const updatedTabs = [...prevTabs];
        updatedTabs[activeTabIndex] = {
            ...updatedTabs[activeTabIndex],
            templateId: templateId,
        };
        return updatedTabs;
    });
    }
    catch (error) {
      console.error('Failed to update summary template ID', error);
    }
  };

  const addNewSummary = async () => {
    try {
      const new_summary = await createSummary(id);

      const newTab = {
        summaryId: new_summary.summary_id,
        consultationId: new_summary.consultation_id,
        label: new_summary.title,
        templateId: new_summary.template_id,
        summary: new_summary.summary
      };

      setSummaryTabs([...summaryTabs, newTab]);
      setActiveTabIndex(summaryTabs.length);
    } catch (error) {
      console.error('Failed to create a new summary:', error);
    }
    dispatchContextUpdateEvent();
  };

  const deleteSummary = async () => {
    try {
      const summaryIdToDelete = summaryTabs[activeTabIndex].summaryId;
  
      // Call the backend to delete the summary
      await deleteSummaryFromBackend(summaryIdToDelete);
  
      // Filter out the deleted tab and renumber the remaining tabs
      const filteredTabs = summaryTabs.filter((_, i) => i !== activeTabIndex);
      const renumberedTabs = filteredTabs.map((tab, i) => ({
        ...tab,
        title: `Summary ${i + 1}`,
      }));
  
      // Update state with the new list of tabs
      setSummaryTabs(renumberedTabs);
  
      // Reset the active tab index if necessary
      setActiveTabIndex(0);
      
      console.log('Summary deleted successfully');
    } catch (error) {
      console.error('Failed to delete summary:', error);
    }
    dispatchContextUpdateEvent();
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      alert('Content copied to clipboard!');
    });
  };


  const generateContent = async () => {
     // Step 1: Find the template based on the active tab's templateId
      const templateId = summaryTabs[activeTabIndex].templateId;
      const template = templates.find(t => t.id === templateId);
      const template_content = template.content;

      if (!template) {
        console.error('Template not found');
        return;
      }

    const generatedSummary = await generateSummary(transcript, template_content, notes);
    
    if (generatedSummary) {
        // Update UI with context
        updateSummary(summaryTabs[activeTabIndex].summaryId, generatedSummary);

        // Update the summary in the local state
        const updatedTabs = [...summaryTabs];
        updatedTabs[activeTabIndex].summary = generatedSummary;
        setSummaryTabs(updatedTabs); // Trigger a state update to refresh the UI

    } else {
        console.error('Failed to generate summary');
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        aria-label="consultation summary tabs"
        variant="scrollable" // Enable scrolling
        scrollButtons="auto" // Show scroll buttons automatically when needed
        allowScrollButtonsMobile // Ensure scroll buttons are visible on mobile
      >
        {summaryTabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
        <IconButton onClick={addNewSummary} color="primary" sx={{ p: 0.5 }}>
          <AddIcon />
        </IconButton>
      </Tabs>
      {summaryTabs.map((summary, index) => (

        activeTabIndex === index && (
          <Box
            key={index}
            role="tabpanel"
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <FormControl fullWidth>
              <InputLabel id="template-select-label">Select Template</InputLabel>
              <Select
                labelId="template-select-label"
                id="template-select"
                value={summary.templateId} // we need to add + 1 because templateIdMap starts at id=0 and not id=1
                onChange={(e) => handleTemplateChange(e.target.value)}
                label={"Select Template"}
              >
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.title}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>

          {isLoadingAnimation ? 
          <Skeleton 
            variant="rounded" 
            width="100%"  // Match the width of the TextField
            height="212.2px" // Adjust the height automatically to fit the content
            sx={{
              borderRadius: '4px', // Match the TextField's border-radius
              padding: '16.5px 14px', // Match the TextField's padding
              boxSizing: 'border-box', // Ensure padding is included in the width and height/>
              border: '1px solid rgba(0, 0, 0, 0.23)'
            }}
          />
          :

          <TextField
            fullWidth
            multiline
            value={summaryTabs[activeTabIndex].summary}
            InputProps={{
              readOnly: true, // Make the field read-only
            }}
            variant="outlined"
            rows={8}  // Set an appropriate minimum height
            sx={{ 
              fontFamily: 'Arial, sans-serif', 
              fontSize: '14px', 
              lineHeight: '1.6',
              overflow: 'auto', // Allows scrolling if content overflows
            }}
          />
          }


            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                onClick={() => generateContent(index)}
                variant="outlined"
                disabled={summary.templateId === 0}
              >
                {summaryTabs[activeTabIndex].summary ? 'Regenerate' : 'Generate'}
              </Button>
              <IconButton onClick={() => copyToClipboard(summaryTabs[activeTabIndex].summary)} size="small" disabled={!summaryTabs[activeTabIndex].summary}>
                <ContentCopyIcon />
              </IconButton>
              <IconButton onClick={() => deleteSummary()} size="small">
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        )
      ))}
    </Box>
  );
};

export default ConsultationSummaryTabs;