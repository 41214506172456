import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchConsultationDetails } from '../services/databaseService'; // Import your fetch function
import dayjs from 'dayjs';

// Create a Context for the consultation ID and details
const ConsultationIdContext = createContext();

// Create a custom hook to use the ConsultationIdContext
export const useConsultationId = () => {
    const context = useContext(ConsultationIdContext);
    if (!context) {
        throw new Error("useConsultationId must be used within a ConsultationIdProvider");
    }
    return context;
};

// In your context provider or a utility file
export const dispatchContextUpdateEvent = () => {
    console.log("dispatch update event");
    const event = new Event('contextUpdate');
    window.dispatchEvent(event);
};

// Create a Provider component
export const ConsultationIdProvider = ({ children }) => {
    const [consultationId, setConsultationId] = useState(null);
    const [consultationDetails, setConsultationDetails] = useState({
        id: '',
        title: '',
        date: dayjs(),
        patient: '',
        audioFile: '',
        transcript: '',
        notes: ''
    });

    // Listen to context update trigger and fetch consultation details
    useEffect(() => {
        const handleContextUpdate = async () => {
            if (consultationId) {
                console.log("contextUpdate event received, fetching consultation details...");
                const subscriptionId = localStorage.getItem('subscriptionId'); // Get subscriptionId from localStorage
                const data = await fetchConsultationDetails(consultationId, subscriptionId); // Pass subscriptionId to fetch function
                if (data) {
                    setConsultationDetails({
                        id: data.id,
                        title: data.title,
                        name: data.name,
                        date: dayjs(data.date),
                        audioFile: data.audio_file,
                        transcript: data.transcript,
                        notes: data.notes
                    });
                    console.log("Consultation details updated after contextUpdate:", data);
                }
            }
        };

        window.addEventListener('contextUpdate', handleContextUpdate);

        return () => {
            window.removeEventListener('contextUpdate', handleContextUpdate);
        };
    }, [consultationId]);

    // Load consultation details whenever the consultationId changes
    useEffect(() => {
        console.log("consultation id has been changed ... loading consultation details")
        const loadConsultationDetails = async () => {
            if (consultationId) {
                const subscriptionId = localStorage.getItem('subscriptionId'); // Get subscriptionId from localStorage
                const data = await fetchConsultationDetails(consultationId, subscriptionId); // Pass subscriptionId to fetch function
                if (data) {
                    setConsultationDetails({
                        id: data.id,
                        title: data.title,
                        name: data.name,
                        date: dayjs(data.date),
                        audioFile: data.audio_file,
                        transcript: data.transcript,
                        notes: data.notes
                    });
                }
                console.log(data);
            }
        };

        loadConsultationDetails();
    }, [consultationId]);

    return (
        <ConsultationIdContext.Provider value={{ consultationId, setConsultationId, consultationDetails }}>
            {children}
        </ConsultationIdContext.Provider>
    );
};
