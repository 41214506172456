import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#145439', // Primary color
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#277460', //  Secondary color (not used right now)
    },
    background: {
      default: '#ffffff', // Background color for the app
    },
    text: {
      primary: '#0d1423', // Primary text color
      secondary: '#0d1423', // Secondary text color
    },
  },
  typography: {
    fontFamily: 'Open Sans, Arial, sans-serif', // Default font family for body text
    h1: {
      fontFamily: 'Montserrat, Arial, sans-serif', 
    },
    h2: {
      fontFamily: 'Montserrat, Arial, sans-serif', 
    },
    h3: {
      fontFamily: 'Montserrat, Arial, sans-serif', 
    },
    h4: {
      fontFamily: 'Montserrat, Arial, sans-serif', 
    },
    h5: {
      fontFamily: 'Montserrat, Arial, sans-serif', 
    },
    h6: {
      fontFamily: 'Montserrat, Arial, sans-serif', 
    },
    subtitle1: {
      fontFamily: 'Open Sans, Arial, sans-serif', 
    },
    subtitle2: {
      fontFamily: 'Open Sans, Arial, sans-serif', 
    },
    body1: {
      fontFamily: 'Open Sans, Arial, sans-serif', 
    },
    body2: {
      fontFamily: 'Open Sans, Arial, sans-serif', 
    },
    button: {
      fontFamily: 'Montserrat, Arial, sans-serif',
    },
  },
});

export default theme;